import '../css/app.css';
import React from "react";
import { withTranslation } from "react-i18next";
import { getText } from "./functions";

class Reps extends React.Component {

  render() {
  return (
    <div>
        <div id="reps"></div>
          <div className="App-reps">
            <h2 className='App-size lg'>{getText(this.props.t("reps_title"))}</h2>
            <h2 className='App-size sm'>{getText(this.props.t("reps_title"))}</h2>
          </div>
          <div className="App-size lg">
            <div style={{display: "flex", flexDirection: "row", width: "80vw", alignSelf: "center", justifyContent: "space-evenly"}}>
              <div>
                <div >Yves St-Pierre</div>
                <div className='Reps-title'>{this.props.t("reps_qc")}</div>
                <div className='Reps-text'>{this.props.t("reps_cell").concat(": 418-714-1724")}</div>
                <div className='Reps-text'>{this.props.t("reps_toll_free").concat(": 1-877-863-5885")}</div>
              </div>
              <div>
                <div>Sylvain Clermont</div>
                <div className='Reps-title'>{this.props.t("reps_us")}</div>
                <div className='Reps-text'>{this.props.t("reps_cell").concat(": 581-909-2659")}</div>
                <div className='Reps-text'>{this.props.t("reps_toll_free").concat(": 1-877-863-5885")}</div>
              </div>
            </div>
          </div>
          <div className="App-size sm">
            <br />
            <div>
              <div >Yves St-Pierre</div>
              <div className='Reps-title'>{this.props.t("reps_qc")}</div>
              <div className='Reps-text'>{this.props.t("reps_cell").concat(": 418-714-1724")}</div>
              <div className='Reps-text'>{this.props.t("reps_toll_free").concat(": 1-877-863-5885")}</div>
            </div>
            <br />
            <div>
              <div>Sylvain Clermont</div>
              <div className='Reps-title'>{this.props.t("reps_us")}</div>
              <div className='Reps-text'>{this.props.t("reps_cell").concat(": 581-909-2659")}</div>
              <div className='Reps-text'>{this.props.t("reps_toll_free").concat(": 1-877-863-5885")}</div>
            </div>
          </div>
    </div>
  );
}
}
export default withTranslation()(Reps);
